import React, { useEffect, useState } from "react"
import CopyIcon from "../assets/icons/CopyIcon"
// import Expand from "../assets/icons/Expand"

const CodeExample = ({ codeExample, active }) => {
  const [code, setCode] = useState([])
  const [isCopied, setIsCopied] = useState(false)

  let codeGroups = []

  const copyToClipboard = code => {
    const filteredCode = code
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
      .replace("<pre><code>", "")
      .replace("</code></pre>", "")
    navigator.clipboard.writeText(filteredCode)
    setIsCopied(true)
  }

  useEffect(() => {
    const codeChunks = codeExample.code.split("\n").map(function (item) {
      return (
        item
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&amp;/g, "&")
          // .replace(/\s/g, "&nbsp;")
          .replace("<pre><code>", "")
          .replace("</code></pre>", "")
      )
    })

    let currentLineNumber = 0

    if (codeGroups.length === 0) {
      codeExample.content.map((item, key) => {
        // get the line_number
        let lineNumber = item.line_number
        if (key === 0 && !lineNumber) {
          lineNumber = 0
        }

        const nextItem = codeExample.content[key + 1]
        let endLineNumber = 0

        if (nextItem !== undefined) {
          endLineNumber = codeExample.content[key + 1].line_number - 1
        } else {
          endLineNumber = codeChunks.length
        }

        // now get all the code lines upto this current line number
        const codeLines = codeChunks.slice(currentLineNumber, endLineNumber)
        // const codeLines = codeChunks.slice(0, 48);
        currentLineNumber = endLineNumber

        // now store the text in an object key
        codeGroups.push({
          content: item,
          code: codeLines,
        })
      })
    }
    setCode(codeGroups)
  }, [active])

  return (
    <div className={`code-block${active === codeExample.id ? " active" : ""}`}>
      {/* {console.log(typeof active, typeof codeExample.id)} */}
      <div className="code-section title-section">
        <div className="main-col-one">
          <h2 className="code-title">{codeExample.title}</h2>
        </div>
        <div className="main-col-two">
          <div className="code-actions">
            {/* <div className="expand-code">
              <Expand />
            </div> */}
            <div
              className={isCopied ? "copy-code copied" : "copy-code"}
              onClick={() => copyToClipboard(codeExample.code)}
            >
              <CopyIcon />
              {isCopied && <div>Copied to clipboard</div>}
            </div>
          </div>
        </div>
      </div>
      {code &&
        code.map((item, key) => {
          return (
            <div className="code-section" key={key}>
              <div
                className="main-col-one"
              >
                <div className="explanation-wrap"
                  dangerouslySetInnerHTML={{ __html: item.content.text }}>
                </div>
              </div>
              <div className="main-col-two">
                <div className="code-col">
                  <pre>
                    {item.code.map(function (item, i) {
                      return (
                        <span key={i}>
                          {item}
                          <br />
                        </span>
                      )
                    })}
                  </pre>
                </div>
              </div>
            </div>
          )
        })}

      {/* <div className="main-col-one">
        {codeExample.content &&
          codeExample.content.map((item, key) => {
            ;<div className="text" key={key}></div>
          })}
      </div>
      <div className="main-col-two">
        {codeExample.code && (
          <div className="code-col">
            {codeExample.code.split("\n").map(function (item) {
              return (
                <span>
                  {item}
                  <br />
                </span>
              )
            })}
          </div>
        )}
      </div> */}
    </div>
  )
}

export default CodeExample
