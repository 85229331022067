import React from "react"

function CopyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path d="M16.9 3.4H4.5c-.6 0-1.1.5-1.1 1.1v12.4c0 .6.5 1.1 1.1 1.1h12.4c.6 0 1.1-.5 1.1-1.1V4.5c0-.6-.5-1.1-1.1-1.1zm0 13.5H4.5V4.5h12.4v12.4z" />
      <path d="M1.1 1.1h12.4v1.1h1.1V1.1c0-.6-.5-1.1-1.1-1.1H1.1C.5 0 0 .5 0 1.1v12.4c0 .6.5 1.1 1.1 1.1h1.1v-1.1H1.1V1.1z" />
    </svg>
  )
}

export default CopyIcon
