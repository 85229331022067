import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import IntroBlock from "../components/blocks/IntroBlock"
import CodeExample from "../components/CodeExample"
import Seo from "../components/seo"

const ExampleArticleTemplate = ({ data }) => {
  const [selectedCode, setSelectedCode] = useState(
    data.strapiExampleArticle.code_examples
      ? data.strapiExampleArticle.code_examples[0].id
      : null
  )

  const onSelectUpdate = event => {
    setSelectedCode(event.target.value)
  }

  return (
    <div className="example-article-page">
      <Layout>
        <div className="example-article-intro">
          <div className="main-col-one">
            {data.strapiExampleArticle.intro && (
              <React.Fragment>
                <Seo title={data.strapiExampleArticle.intro.title} />
                <IntroBlock content={data.strapiExampleArticle.intro} />
              </React.Fragment>
            )}

            <div className="example-selector">
              <div className="form-label">Choose an example:</div>
              <select onChange={onSelectUpdate} value={selectedCode}>
                {data.strapiExampleArticle.code_examples &&
                  data.strapiExampleArticle.code_examples.map(
                    (codeExample, key) => {
                      return (
                        <option value={codeExample.id} key={key}>
                          {codeExample.title}
                        </option>
                      )
                    }
                  )}
              </select>
            </div>
          </div>
          <div className="main-col-two"></div>
        </div>
        <section className="code-examples">
          {data.strapiExampleArticle.code_examples &&
            data.strapiExampleArticle.code_examples.map((codeExample, key) => {
              return (
                <CodeExample
                  codeExample={codeExample}
                  key={key}
                  active={parseInt(selectedCode)}
                />
              )
            })}
        </section>
      </Layout>
    </div>
  )
}

export default ExampleArticleTemplate

export const query = graphql`
  query ExampleArticleTemplate($id: String!) {
    strapiExampleArticle(id: { eq: $id }) {
      title
      intro {
        label
        title
        subtitle
        introduction {
          content
        }
      }
      code_examples {
        id
        title
        code
        content {
          line_number
          strapi_component
          text
        }
      }
    }
  }
`
