import React from "react"

const IntroBlock = ({ content }) => {
  return (
    <div className="intro-block block">
      {content.label && <div className="label">{content.label}</div>}
      {content.title && <h1>{content.title}</h1>}
      {content.subtitle && (
        <h2
          className="subtitle"
          dangerouslySetInnerHTML={{ __html: content.subtitle }}
        />
      )}
      {content.introduction && (
        <div
          className="introduction"
          dangerouslySetInnerHTML={{ __html: content.introduction.content }}
        />
      )}
    </div>
  )
}

export default IntroBlock
